import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL, AppConstant } from 'AppConstant'
import { APIStatus } from '../types'

export enum NoteStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
}

export interface INotes {
  id: string
  visitId: string
  chiropractorId: string
  subjective: string
  objective: string
  assessment: string
  plan: string
  status: string
  createdAt: string
  updatedAt: string
  patient: {
    id: string
    stripeCustomerId: string
    screeningStatus: string
    isQuestionareCompleted: boolean
    screenerFeedback: string
    screeningSessionRecording: string
    user: {
      id: string
      firstname: string
      lastname: string
      phone: string
      dob: string
      email: string
      profilePic: string
      screeningStatus: string
      gender: string
    }
  }
}

interface NotesState {
  notes: Array<INotes>
  retryNote: any
  notesStatus: APIStatus
  retryNotesStatus: APIStatus
  patientNotesStatus: APIStatus
  patientNotesModalStatus: APIStatus
  autoUpdate: any
  autoUpdateNoteStatus: APIStatus
  currentNote: INotes | null
  currentNoteUpdateStatus?: APIStatus
  patientNotes: {
    [patientId: string]: {
      apiStatus: APIStatus
      notes: Array<INotes>
    }
  }
  patientNotesModal: {
    [patientId: string]: {
      apiStatus: APIStatus
      notes: Array<INotes>
    }
  }
}

const initialState: NotesState = {
  notes: [],
  retryNote: null,
  currentNote: null,
  notesStatus: APIStatus.idle,
  retryNotesStatus: APIStatus.idle,
  patientNotesStatus: APIStatus.idle,
  patientNotesModalStatus: APIStatus.idle,
  autoUpdate: null,
  autoUpdateNoteStatus: APIStatus.idle,
  patientNotes: {
    patientId: {
      apiStatus: APIStatus.idle,
      notes: [],
    },
  },
  patientNotesModal: {
    patientId: {
      apiStatus: APIStatus.idle,
      notes: [],
    },
  },
}

export const getNotesAsync = createAsyncThunk('visit/getNotesAsync', async (patientId: string, thunkAPI) => {
  //${process.env.REACT_APP_API_BASE_URL || '/api'
  const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

  let response = await axios
    .get(`${API_URL}/visit/patient/${patientId}/notes`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
      },
    })
    .then((res) => res)
    .catch((err) => err.response)

  if (response.data && response.data.code === 'success') {
    return thunkAPI.fulfillWithValue({
      patientId,
      notes: response.data.data,
    })
  } else {
    return thunkAPI.rejectWithValue(response.data.message)
  }
})

export const getNotesByStatusAsync = createAsyncThunk('visit/getNotesByStatusAsync', async (_, thunkAPI) => {
  //${process.env.REACT_APP_API_BASE_URL || '/api'
  const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

  let response = await axios
    .get(`${API_URL}/visit/notes/status`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
      },
    })
    .then((res) => res)
    .catch((err) => err.response)

  if (response.data && response.data.code === 'success') {
    return thunkAPI.fulfillWithValue(response.data.data)
  } else {
    return thunkAPI.rejectWithValue(response.data.message)
  }
})

export const retryNotesAsync = createAsyncThunk(
  'visit/retryNotesAsync',
  async ({ notesId, noteToRetry }: { notesId: string; noteToRetry: string }, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

    let response = await axios
      .put(
        `${API_URL}/clinic/note/${notesId}/${noteToRetry}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
          },
        },
      )
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        noteValue: response.data.data[noteToRetry],
        noteToUpdate: noteToRetry,
        noteId: notesId,
      })
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const updateNotesAsync = createAsyncThunk(
  'visit/updateNotesByStatusAsync',
  async ({ updatedNotes, notes }: { updatedNotes: any; notes?: any }, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)
    const restofTheNotes = notes.filter((n: INotes) => n.id !== updatedNotes.id)
    //notes.status = NoteStatus.ACCEPTED

    let response = await axios
      .put(`${API_URL}/visit/notes/${updatedNotes.id}`, updatedNotes, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue({
        currentNote: updatedNotes,
        notes: restofTheNotes,
      })
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

export const autoUpdateNotesAsync = createAsyncThunk(
  'visit/autoUpdateNotesByStatusAsync',
  async ({ updatedNotes }: { updatedNotes: any }, thunkAPI) => {
    //${process.env.REACT_APP_API_BASE_URL || '/api'
    const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)
    //notes.status = NoteStatus.ACCEPTED
    let response = await axios
      .put(`${API_URL}/visit/notes/${updatedNotes.id}`, updatedNotes, {
        headers: {
          Authorization: `Bearer ${token}`,
          'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
        },
      })
      .then((res) => res)
      .catch((err) => err.response)
    if (response.data && response.data.code === 'success') {
      return thunkAPI.fulfillWithValue(response.data.data)
    } else {
      return thunkAPI.rejectWithValue(response.data.message)
    }
  },
)

// Patient details notes modal

export const getNotesModalAsync = createAsyncThunk('visit/getNotesModalAsync', async (patientId: string, thunkAPI) => {
  //${process.env.REACT_APP_API_BASE_URL || '/api'
  const token = localStorage.getItem(AppConstant.ACCESS_TOKEN)

  let response = await axios
    .get(`${API_URL}/visit/patient/${patientId}/notes`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Platform-Id': process.env.REACT_APP_PLATFORM_ID,
      },
    })
    .then((res) => res)
    .catch((err) => err.response)

  if (response.data && response.data.code === 'success') {
    return thunkAPI.fulfillWithValue({
      patientId,
      notes: response.data.data,
    })
  } else {
    return thunkAPI.rejectWithValue(response.data.message)
  }
})

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setCurrentNote: (state, action) => {
      state.currentNote = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotesAsync.fulfilled, (state, action) => {
      //state.notes = action.payload.notes
      // state.notesStatus = APIStatus.fulfilled
      state.patientNotesStatus = APIStatus.fulfilled
      state.patientNotes[action.payload.patientId] = {
        notes: action.payload.notes.sort((a: any, b: any) => {
          const dateA = new Date(a.createdAt).getTime()
          const dateB = new Date(b.createdAt).getTime()
          return dateB - dateA
        }),
        apiStatus: APIStatus.fulfilled,
      }
    })
    builder.addCase(getNotesAsync.rejected, (state, action) => {
      state.patientNotesStatus = APIStatus.rejected
    })
    builder.addCase(getNotesAsync.pending, (state, action) => {
      state.patientNotesStatus = APIStatus.pending
    })

    builder.addCase(retryNotesAsync.fulfilled, (state, action) => {
      if (state.currentNote && state.currentNote.id === action.payload.noteId) {
        ;(state.currentNote as any)[action.payload.noteToUpdate] = action.payload.noteValue
      }
      state.retryNotesStatus = APIStatus.fulfilled
    })
    builder.addCase(retryNotesAsync.rejected, (state, action) => {
      state.retryNotesStatus = APIStatus.rejected
    })
    builder.addCase(retryNotesAsync.pending, (state, action) => {
      state.retryNotesStatus = APIStatus.pending
    })

    builder.addCase(getNotesByStatusAsync.fulfilled, (state, action) => {
      state.notes = action.payload

      state.notesStatus = APIStatus.fulfilled
    })
    builder.addCase(getNotesByStatusAsync.rejected, (state, action) => {
      state.notesStatus = APIStatus.rejected
    })
    builder.addCase(getNotesByStatusAsync.pending, (state, action) => {
      state.notesStatus = APIStatus.pending
    })

    builder.addCase(updateNotesAsync.fulfilled, (state, action) => {
      state.currentNoteUpdateStatus = APIStatus.fulfilled
      // let res = [...state.notes.filter((n) => n.id !== action.payload.id)]
      state.notes = action.payload.notes
      state.currentNote = action.payload.notes[0]
      state.currentNoteUpdateStatus = undefined
    })

    builder.addCase(updateNotesAsync.rejected, (state, action) => {
      state.currentNoteUpdateStatus = APIStatus.rejected
    })
    builder.addCase(updateNotesAsync.pending, (state, action) => {
      state.currentNoteUpdateStatus = APIStatus.pending
    })

    //auto update note
    builder.addCase(autoUpdateNotesAsync.fulfilled, (state, action) => {
      state.autoUpdateNoteStatus = APIStatus.fulfilled
      state.autoUpdate = action.payload
      if (state.currentNote) {
        state.currentNote.subjective = action.payload.subjective
        state.currentNote.objective = action.payload.objective
        state.currentNote.assessment = action.payload.assessment
        state.currentNote.plan = action.payload.plan
      }
    })
    builder.addCase(autoUpdateNotesAsync.rejected, (state, action) => {
      state.autoUpdateNoteStatus = APIStatus.rejected
    })
    builder.addCase(autoUpdateNotesAsync.pending, (state, action) => {
      state.autoUpdateNoteStatus = APIStatus.pending
    })

    // getNotesModalAsync
    builder.addCase(getNotesModalAsync.fulfilled, (state, action) => {
      //state.notes = action.payload.notes
      // state.notesStatus = APIStatus.fulfilled

      state.patientNotesModalStatus = APIStatus.fulfilled
      state.patientNotesModal[action.payload.patientId] = {
        notes: action.payload.notes.sort((a: any, b: any) => {
          const dateA = new Date(a.createdAt).getTime()
          const dateB = new Date(b.createdAt).getTime()
          return dateB - dateA
        }),
        apiStatus: APIStatus.fulfilled,
      }
    })
    builder.addCase(getNotesModalAsync.rejected, (state, action) => {
      state.patientNotesModalStatus = APIStatus.rejected
    })
    builder.addCase(getNotesModalAsync.pending, (state, action) => {
      state.patientNotesModalStatus = APIStatus.pending
    })
  },
})

export const { setCurrentNote } = notesSlice.actions

export default notesSlice.reducer
